import Item from "./Item";

const Education = ({ resume, lang }) => {
  return (
    <div>
      <h3>{lang === "de" ? "Ausbildung" : "Education"}</h3>
      <hr />
      <ul>
        {resume?.education?.map((item) => (
          <Item key={item.name} item={item} />
        ))}
      </ul>
    </div>
  );
};

export default Education;
