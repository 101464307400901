import QRCode from "react-qr-code";

const QRCodeBlock = ({ url, color }) => {
  return (
    <div className="qrcode">
      <QRCode fgColor="#5d70e6" size="160" level="H" value={url} title={url} />
      <p style={{ color: color }}>{url}</p>
    </div>
  );
};

export default QRCodeBlock;
