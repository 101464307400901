import { TagCloud } from "react-tagcloud";

const Skills = ({ resume }) => {
  return (
    <div>
      <h3>Skills</h3>
      <hr />

      <div className="tagCloud">
        {resume.name && (
          <TagCloud
            shuffle="true"
            minSize={5}
            maxSize={27}
            tags={resume?.skills}
            colorOptions={{ luminosity: "dark", hue: "blue" }}
          />
        )}
      </div>
    </div>
  );
};

export default Skills;
