const Header = ({ resume }) => {
  return (
    <header>
      <h2 id="name">{resume?.name}</h2>
      <p>
        <span className="grey">E-Mail:&nbsp;&nbsp;&nbsp;</span> {resume?.email}
      </p>
      <p className="text grey">GitHub: &nbsp;&nbsp;</p>
      <a href={resume?.github}>{resume?.github}</a>

      <br />

      <p className="text grey">Xing: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
      <a href={resume?.xing}>{resume.xing}</a>
      <br />

      <p className="text grey">Projects: </p>
      <a href="https://tino.sh/projects/">https://tino.sh/projects</a>
    </header>
  );
};

export default Header;
