import Item from "./Item";

const Experience = ({ resume, lang }) => {
  return (
    <div>
      <h3>{lang === "de" ? "Erfahrung" : "Experience"}</h3>
      <hr />
      <ul>
        {resume?.experience?.map((item) => (
          <Item key={item?.name} item={item} />
        ))}
      </ul>
    </div>
  );
};

export default Experience;
