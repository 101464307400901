const Item = ({ item, disable }) => {
  return (
    <div>
      <li>
        <div>
          <div className={item.description && "justify-content-between"}>
            <span className="bold">
              {item?.name} {item?.position && " / " + item?.position}
            </span>
            <span className="date">
              {" "}
              {item?.startTime} {item?.endTime}
            </span>
          </div>
        </div>
        <ul>
          {item.description &&
            item.description.map((desc) => <li key={desc}> {desc} </li>)}
        </ul>
      </li>
    </div>
  );
};

export default Item;
