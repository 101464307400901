import Education from "./components/Education";
import Experience from "./components/Experience";
import Header from "./components/Header";
import QRCodeBlock from "./components/QRCodeBlock";
import Skills from "./components/Skills";
import Summary from "./components/Summary";

import { useState, useEffect } from "react";

const App = () => {
  const [resume, SetResume] = useState({});
  const [lang, setLang] = useState("de");

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const param = urlParams.get("lang");
    param && setLang(param);
  }, []);

  useEffect(() => {
    fetch(`https://json.tino.sh/cv/${lang}`)
      .then((res) => res.json())
      .then((data) => {
        SetResume(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [lang]);

  return (
    <div className="container">
      <Header resume={resume} lang={lang} />
      <Summary resume={resume} lang={lang} />
      <Education resume={resume} lang={lang} />
      <Experience resume={resume} lang={lang} />
      <Skills resume={resume} lang={lang} />
      {lang ? (
        <QRCodeBlock color="#5d70e6" url={`https://cv.tino.sh/?lang=${lang}`} />
      ) : (
        <QRCodeBlock color="#5d70e6" url="https://cv.tino.sh/?lang=de" />
      )}
    </div>
  );
};

export default App;
