const Summary = ({ resume, lang }) => {
  return (
    <div>
      <h3>{lang === "de" ? "Einleitung" : "Summary"}</h3>
      <hr className="styles-module" />
      <p>{resume?.summary}</p>
    </div>
  );
};

export default Summary;
